import clsx from 'clsx';
import { LOGIN, SIGN_UP } from 'components/pages/pages';
import { useRouter } from 'next/router';
import React from 'react';
import Txt from '../../atoms/Txt/Txt';
import classes from './EntryForm.module.scss';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';

export interface Props {
  defaultToLogin?: boolean;
  showLoginForm?: boolean;
  onToggleLoginForm?: (showLogin: boolean) => void;
  onAutofillDetected?: () => void;
  disabled?: boolean;
  navRouteToPage?: boolean;
  disableRedirectAfterLogin?: boolean;
}

const EntryForm: React.FC<Props> = ({
  defaultToLogin,
  showLoginForm,
  onToggleLoginForm,
  onAutofillDetected,
  disabled,
  navRouteToPage,
  disableRedirectAfterLogin,
}) => {
  const [showLogin, setShowLogin] = React.useState(Boolean(showLoginForm || defaultToLogin));
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const emailInputRef = React.useRef<HTMLInputElement>(null);
  const passwordInputRef = React.useRef<HTMLInputElement>(null);
  const emailAutofilled = false; //useDetectAutofill(emailInputRef);
  const passwordAutofilled = true; //useDetectAutofill(passwordInputRef);
  const formAutofilled = emailAutofilled && passwordAutofilled;
  const [autofillSwitched, setAutofillSwitched] = React.useState(false);
  const router = useRouter();

  React.useEffect(() => {
    if (showLoginForm !== undefined) {
      setShowLogin(showLoginForm);
    }
  }, [showLoginForm]);

  React.useEffect(() => {
    if (!autofillSwitched && formAutofilled && onAutofillDetected) {
      setAutofillSwitched(true);
      onAutofillDetected();
    }
  }, [autofillSwitched, formAutofilled, onAutofillDetected]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const handleSwitchMode = (isLoginClick: boolean) => {
    if (navRouteToPage) {
      isLoginClick ? router.push(LOGIN.path) : router.push(SIGN_UP.path);
    } else if (onToggleLoginForm) {
      onToggleLoginForm(isLoginClick);
    } else {
      setShowLogin(isLoginClick);
    }
  };

  const formSettings = {
    submitDisabled: Boolean(((!email || !password) && !formAutofilled) || disabled),
  };
  const emailInputSettings = {
    onChange: handleEmailChange,
    type: 'text',
    id: 'Login-form-email',
    title: 'Email',
    value: email,
    placeholder: 'Email',
    // autoComplete: 'username',
    autoComplete: 'off',
    disabled: !!disabled,
    ref: emailInputRef,
    className: classes.input,
  };
  const passwordInputSettings = {
    onChange: handlePasswordChange,
    id: 'Login-form-password',
    title: 'Password',
    value: password,
    placeholder: 'Password',
    disabled: !!disabled,
    ref: passwordInputRef,
    className: classes.input,
  };

  return (
    <>
      <div className={classes.signUpFormSelector}>
        <button
          className={classes.signUpToggle}
          type="button"
          onClick={() => handleSwitchMode(false)}
        >
          <Txt component="h3" className={showLogin ? classes.inactive : classes.active}>
            Sign Up
          </Txt>
          <span className={clsx(classes.indicator, !showLogin && classes.activeIndicator)} />
        </button>
        <button
          className={classes.signUpToggle}
          type="button"
          onClick={() => handleSwitchMode(true)}
        >
          <Txt component="h3" className={showLogin ? classes.active : classes.inactive}>
            Log In
          </Txt>
          <span className={clsx(classes.indicator, showLogin && classes.activeIndicator)} />
        </button>
      </div>
      {showLogin ? (
        <LoginForm
          formSettings={formSettings}
          emailSettings={emailInputSettings}
          passwordSettings={passwordInputSettings}
          email={email}
          disableRedirectAfterLogin={disableRedirectAfterLogin}
          password={password}
          onSwitchMode={() => handleSwitchMode(false)}
        />
      ) : (
        <SignUpForm
          formSettings={formSettings}
          emailSettings={emailInputSettings}
          passwordSettings={passwordInputSettings}
          email={email}
          password={password}
          onSwitchMode={() => handleSwitchMode(true)}
        />
      )}
    </>
  );
};
export default EntryForm;
