import React from 'react';
import clsx from 'clsx';
import Mailcheck from 'mailcheck';
import { useSignUp } from '../../templates/AuthenticationProvider/AuthenticationProvider';
import Form from '../../molecules/Form/Form';
import A from '../../atoms/A/A';
import TextInput from '../../atoms/TextInput/TextInput';
import PasswordInput from '../../atoms/TextInput/PasswordInput';
import usePromiseState from '../../../hooks/usePromiseState';
import { FormSettings, InputSettings } from './types';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import { PROTECT_YOUR_ACCOUNT, SCREENER_SURVEY, TERMS_AND_CONDITIONS } from '../../pages/pages';
import {
  handleAcquisitionEvents,
  handleUserUpdated,
} from '../../templates/TrackingProvider/trackingUtils';
import Txt from '../../atoms/Txt/Txt';
import { useRouter } from 'next/router';
import classes from './SignUpForm.module.scss';
import Notice from 'components/atoms/Notice/Notice';
export interface Props {
  formSettings: FormSettings;
  emailSettings: InputSettings;
  passwordSettings: InputSettings;
  email: string;
  password: string;
  onSwitchMode: () => void;
}

interface MailcheckSuggestion {
  address: string;
  domain: string;
  full: string;
}

const SignUpForm: React.FC<Props> = ({
  formSettings,
  emailSettings,
  passwordSettings,
  email,
  password,
  onSwitchMode,
}) => {
  const signUp = useSignUp();
  const [handleSignUp, signUpState] = usePromiseState(signUp);
  const [marketingConsent, setMarketingConsent] = React.useState(false);
  const [mailcheckSuggestion, setMailcheckSuggestion] = React.useState<MailcheckSuggestion | null>(
    null,
  );
  const router = useRouter();
  const [suggestionRejected, setSuggestionRejected] = React.useState(false);
  const [signUpError, setSignUpError] = React.useState<string | undefined>();

  function handleEmailBlur() {
    Mailcheck.run({
      email,
      suggested(suggestion: MailcheckSuggestion) {
        setMailcheckSuggestion(suggestion);
      },
      empty() {
        setMailcheckSuggestion(null);
      },
    });
  }

  const handleSignUpPress = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const userData = await handleSignUp(email, password, marketingConsent);
      if (userData) {
        handleUserUpdated(userData.guid);
        handleAcquisitionEvents('sign-up', { userGuid: userData.guid });
      }
      router.push(SCREENER_SURVEY.path, undefined, { shallow: true });
    } catch (err: any) {
      setSignUpError(
        err.loginError || 'Network error. Maybe you have tried too many times. Try again later.',
      );
    }
  };
  function handleUseMailCheck() {
    if (emailSettings?.onChange && mailcheckSuggestion) {
      emailSettings?.onChange({
        target: { value: mailcheckSuggestion.full },
      } as React.ChangeEvent<HTMLInputElement>);
      setMailcheckSuggestion(null);
    }
  }

  return (
    <section className={classes.root}>
      <Form
        submitDisabled={formSettings.submitDisabled || signUpState.isLoading}
        onSubmit={handleSignUpPress}
        submitName="Sign Up"
        submitButtonType="primary"
        submitButtonColor="secondary"
      >
        <div className={classes.section}>
          <TextInput {...emailSettings} onBlur={handleEmailBlur} />
          {mailcheckSuggestion && !suggestionRejected && (
            <Notice status={'warning'} className={classes.mailcheck} onClick={handleUseMailCheck}>
              <div>
                Did you mean {mailcheckSuggestion?.address}@
                <strong>{mailcheckSuggestion?.domain}</strong>?
              </div>
            </Notice>
          )}
          <Txt component="p" className={classes.subscript}>
            Got an account?{' '}
            <Txt component="button" type="button" onClick={onSwitchMode}>
              Log In
            </Txt>
          </Txt>
        </div>
        <div className={classes.section}>
          <PasswordInput {...passwordSettings} labelPlaceholder={false} />
          {signUpError !== undefined && (
            <p>
              <Txt component={A} to={PROTECT_YOUR_ACCOUNT.path}>
                How we help protect your account
              </Txt>
            </p>
          )}
          <Txt component="p" className={classes.subscript}>
            By signing up I agree to the{' '}
            <Txt
              component={A}
              className={classes.agreementButton}
              prefetch={false}
              to={TERMS_AND_CONDITIONS.path}
            >
              terms and conditions
            </Txt>
          </Txt>
        </div>
        <label
          className={clsx(classes.subscript, classes.marketing, classes.section)}
          htmlFor="marketing"
        >
          <input
            type="checkbox"
            id="marketing"
            className={classes.checkbox}
            checked={marketingConsent}
            onChange={() => setMarketingConsent(!marketingConsent)}
          />
          <Txt component="p">
            Allow Qmee to send me emails regarding competitions, new features, reminders &amp; other
            promotional marketing messages.
          </Txt>
        </label>
      </Form>
      {signUpError && <ErrorAlert message={signUpError} onClose={() => setSignUpError('')} />}
    </section>
  );
};
export default SignUpForm;
