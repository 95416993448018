import clsx from 'clsx';
import React from 'react';
import classes from './SignUpForm.module.scss';

import { useRouter } from 'next/router';
import usePromiseState from '../../../hooks/usePromiseState';
import A from '../../atoms/A/A';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import PasswordInput from '../../atoms/TextInput/PasswordInput';
import TextInput from '../../atoms/TextInput/TextInput';
import Txt from '../../atoms/Txt/Txt';
import Form from '../../molecules/Form/Form';
import { DASHBOARD, REQUEST_PASSWORD_RESET } from '../../pages/pages';
import { useLogin } from '../../templates/AuthenticationProvider/AuthenticationProvider';
import { FormSettings, InputSettings } from './types';

export interface Props {
  formSettings: FormSettings;
  emailSettings: InputSettings;
  passwordSettings: InputSettings;
  email: string;
  disableRedirectAfterLogin?: boolean;
  password: string;
  onSwitchMode: () => void;
}

const LoginForm: React.FC<Props> = ({
  formSettings,
  emailSettings,
  passwordSettings,
  email,
  password,
  disableRedirectAfterLogin,
  onSwitchMode,
}) => {
  const login = useLogin();
  const [handleLogin, loginState] = usePromiseState(login);
  const [loginError, setLoginError] = React.useState('');
  const router = useRouter();
  const handleLoginPress = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLogin(email, password)
      .then(() =>
        disableRedirectAfterLogin
          ? null
          : router.push(DASHBOARD.path, undefined, { shallow: true }),
      )
      .catch((err: any) =>
        setLoginError(
          err.loginError || 'Network error. Maybe you have tried too many times. Try again later.',
        ),
      );
  };

  return (
    <section className={classes.root}>
      <Form
        submitDisabled={formSettings.submitDisabled || loginState.isLoading}
        onSubmit={handleLoginPress}
        submitName="Log In"
      >
        <div className={classes.section}>
          <TextInput {...emailSettings} />
          <Txt component="p" className={classes.subscript}>
            Need an account?{' '}
            <Txt component="button" type="button" onClick={onSwitchMode}>
              Sign up
            </Txt>
          </Txt>
        </div>
        <div className={classes.section}>
          <PasswordInput {...passwordSettings} labelPlaceholder={false} />
          <Txt
            component={A}
            className={clsx(classes.subscript, classes.forgotPassword)}
            to={REQUEST_PASSWORD_RESET.path}
          >
            Forgot your password?
          </Txt>
        </div>
      </Form>
      {loginError && <ErrorAlert message={loginError} onClose={() => setLoginError('')} />}
    </section>
  );
};
export default LoginForm;
